<template>
    <ListingComponent :info="info"/>
</template>
<script>
import ListingComponent from './ListingPage/index.vue';

export default {
    name : "EbooksCustom",
    components: {
        ListingComponent
    },
    data: function () {
        return {
            info: {
                // pageName : "Ebook",
                // pageUrl : "customEbooks",
                // endpoint : '/admin/all-ebooks',
                // endpointSearch : '/admin/livesearch-ebook',
                // endpointStatus : '/admin/ebook-status',
                // endpointSubscriptionStatus : '/admin/toggle-subscription-status',
                // keysToDisplay : ['id','name','status','is_paid'],
                // addPageUrl : 'ebooks/add',
                // addPageEndpoint : '/admin/add-ebook',



                pageName: "Ebook",
                pageUrl: "/customEbooks",
                endpoint: '/admin/all-ebooks',
                endpointSearch: 'admin/livesearch-ebook',
                keysToDisplay: ['id','image', 'name', 'url', 'status', 'is_paid'],
                endpointStatus: '/admin/ebook-status',
                addPageUrl: '/customEbooks/add',
                addPageEndpoint: '/admin/add-ebook',
                formDataInsert: [
                    { 
                        objKey : 'name',
                        label: 'Title', 
                        name: 'title', 
                        value: '' , 
                        placeholder : 'Enter title here',
                        splitColumnData: false,
                        type : 'input',
                        validationRules : { required: true, max: 100 }
                    },
                    { 
                        objKey : 'author',
                        label: 'Author', 
                        name: 'author', 
                        value: '' , 
                        placeholder : 'Enter author name here',
                        splitColumnData: false,
                        type : 'input',
                        validationRules : { required: true, max: 30 }
                    },
                    { 
                        objKey : 'image',
                        label: 'Image', 
                        name: 'image', 
                        value: '' , 
                        // placeholder : 'Enter author name here',
                        splitColumnData: false,
                        type : 'file-image',
                        validationRules : { required: true }
                    },
                    { 
                        objKey : 'cover_image',
                        label: 'Cover Image', 
                        name: 'cover_image', 
                        value: '' , 
                        // placeholder : 'Enter author name here',
                        splitColumnData: false,
                        type : 'file-image',
                        validationRules : { required: true }
                    },
                    { 
                        objKey : 'ebook',
                        label: 'Ebook', 
                        name: 'ebook', 
                        value: '' , 
                        // placeholder : 'Enter author name here',
                        splitColumnData: false,
                        type : 'file-pdf',
                        validationRules : { required: true }
                    },
                    { 
                        objKey : 'description',
                        label: 'Description', 
                        name: 'description', 
                        value: '' , 
                        placeholder : 'Enter description here',
                        splitColumnData: false,
                        type : 'textarea',
                        validationRules : { required: true }
                    },
                ],


            }
        };
    },

    mounted() {
        this.$store.commit('setInfo',this.info);
    }
}
</script>
